// src/pages/Home.js
import React, { useState } from 'react';
import "./home.css";
import FAQ from "./Faq";
import { Link } from "react-router-dom";
import Features from './Features';
import dimond from "./imgbox1/dimond1.png";

const Home = () => {



  const [faqs, setFaqs] = useState([
  ]);

  const [newFaqs, setNewFaqs] = useState([
    {
      question: "Who are we?",
      answer: "At Poditivity, we transcend beyond merely being a social media platform; we represent a movement fueled by students, catering to students. Our objective is to equip each learner in India with the essential tools, assistance, and network required for academic excellence. We acknowledge the obstacles learners encounter and are dedicated to offering pioneering remedies to tackle them through our on-of-its kind learning management and communication system made for better student-university relation.",
      open: false
    },
    {
      question: "What opportunities do we have?",
      answer: "Poditivity provides a comprehensive suite of services designed to enhance student development and career readiness. Our Student Upskilling Hub offers hands-on industry training, mentorship, diverse courses, and personal growth resources like podcasts and blogs. The Unified EduHub aligns student needs with institutional processes on a single platform, incorporating gamification to make learning engaging. We also ensure a secure environment with our Safe Student Community, where every user is verified through government ID. Finally, our Career Advancement Support facilitates internships, rewards industry training, and offers extensive placement opportunities to help students transition into professional roles.",
      open: false
    },
    {
      question: "What sets Poditivity apart from other social media platforms?",
      answer: "Poditivity stands out from other educational communities by seamlessly integrating the college ecosystem into our platform. We don't just focus on individual learning; we bridge the gap between students, institutions, and industry. By connecting colleges directly with our community, we create a dynamic environment where students can engage with peers, faculty, and industry professionals in real time. This holistic approach ensures that learning is not isolated but enriched by the shared experience and resources of an entire educational ecosystem, fostering a more collaborative and industry-relevant educational journey.",
      open: false
    },
    {
      question: "How can we join Poditivity team?",
      answer: "If you are excited about the concept of Poditivity and interested in joining our team, please reach out to us by sending a mail with your interests and skillset via our contact us page.",
      open: false
    },
    {
      question: "What stage is poditivity app in",
      answer: "We are currently in active development, preparing to launch a beta version soon.",
      open: false
    }
  ]);

  const toggleFAQ = (index, faqList, setFaqList) => {
    setFaqList(
      faqList.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  // const spacing = 2;           (prachu 1)
  // const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const response = await fetch('http://35.154.125.232:3000/api/website/newsletter', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email }),
  //   });

  //   if (response.ok) {
  //     setMessage('Subscribed successfully!');
  //   } else {
  //     setMessage('Subscription failed or email already exists.');
  //   }
  // };

  return (
    <>
      <div className='website'>
        <div className='bgimg'>
          <div className='block'>
            <div>
              <p className='line1'>Welcome to Poditivity: Connecting Minds, Building Success</p>
              <p className='line2'>Poditivity is a super application platform that enables undergraduates to explore and build their professional journey and grow into young skilled professionals. </p>
              <p className='line3 '>Join us in bridging the gap between students and institutions, paving the way for a brighter future</p>
            </div>
            {/* <div className='win'>
              <div className='lineb'>Sign up!</div>
              <p className='line4'>Already a member? <Link to="/login" className='bbbb'>Login here</Link></p>
            </div> */}
          </div>
          {/* *************** PART A ****************** */}

          <div className='bgimgA'>
            <div className='animated-bg'>
              <div className='circle circle1'></div>
              <div className='circle circle2'></div>
              <div className='circle circle3'></div>
            </div>
            <div className='milky'>
              <img src={require("../images/images/College-Girl.png")} className="milky content" alt="main" />
            </div>
          </div>
        </div>

        <div className='giver'>
          <h2 className=' h2'>What we offer at Poditivity</h2>
          <div className='offeringsz'>
            <div className='for_students'>
              <img src={require("../images/images/student.png")} alt="vision" className='for' style={{}} />
              <h3 className='for'>For Students</h3>
              <div className='ceater '>
                <div className='keter'>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p>Acquire industry relevant skills for your success </p>
                  </div>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p>Be up-to-date with super fast college notifications and news  </p>
                  </div>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p>Engage in a secure, supportive social media platform designed for early professionals</p>
                  </div>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p >Join our multi-college students community and get industry ready </p>
                  </div>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p >Easy to find internships and industry connections for early graduates</p>
                  </div>
                </div>
                <div className=" lop ">
                  <Link className="KnowMore inb2" to="/student">
                    <i class="fa-solid fa-arrow-right"></i>
                    <text> Know More</text>
                  </Link>
                </div>
              </div>
            </div>

            <div className='for_institutions'>
              <img src={require("../images/images/college.png")} alt="vision" className='for' style={{}} />
              <h3 className='for'>For Institutions</h3>
              <div className='ceater'>
                <div className='keter'>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p >Advance communication and learning management system</p>
                  </div>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p>Career advancement support with modern skill development</p>
                  </div>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p>Increase student engagement with our interactive and innovative learning tools.</p>
                  </div>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p>Customised AI powered dashboard to monitor faculty and students</p>
                  </div>
                  <div className='offer'>
                    <img src={dimond} alt="lll" />
                    <p> ONEST decentralised network integration for better opportunities and upskilling</p>
                  </div>
                </div>
                <div className="lap">
                  <Link className="KnowMore inb2" to='/institute'>
                    <i class="fa-solid fa-arrow-right"></i>
                    <text> Know More</text>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className=' h2'>
          <h2 className=' h2'>How Does It Work?</h2>
        </div>
        <div className='parts '>
          <li className='part part6'> Sign up and create an institutional profile on Poditivity.                                   </li>
          <li className='part part2'> Seamlessly integrate all students and faculty members into the platform.                     </li>
          <li className='part part3'> Facilitate smooth and effective communication between students, faculty, and administration. </li>
          <li className='part part4'> Access and share a wide range of educational resources and tools.                            </li>
          <li className='part part5'> Benefit of continuous support from our dedicated team.                                     </li>
        </div>
      </section>

      <div className='faqs_header'>
        {/* <div className='center-heading1'>How Does It Work?</div> */}
        {/* <div className="faqs"> */}
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={() => toggleFAQ(index, faqs, setFaqs)} />
        ))}
      </div>

      <div className="features-heading">
        Features
      </div>
      <Features />


      <div className='faqs_header '>
        <div className='center-heading2 '>FAQ</div>
        {/* <div className="faqsh "> */}
        <div className="faqs ">
          {newFaqs.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={() => toggleFAQ(index, newFaqs, setNewFaqs)} />
          ))}
        </div>
        {/* </div> */}
      </div>

      {  /*(prachu 1)
      <div class="subscribe">     
        <h2 class="subscribe__title">Let's keep in touch</h2>
        <p class="subscribe__copy">Subscribe to keep up with fresh news and exciting updates. We promise not to spam you!</p>
        <form class="form">
          <input type="email" class="form__email" placeholder="Enter your email address" required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button class="form__button" type="submit">
            <div class="svg-wrapper-1">
              <div class="svg-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                </svg>
              </div>
            </div>
            <span>Send</span>
          </button>
        </form>

      </div> */}
    </>
  );
}

export default Home;
