// import React, { useState } from "react";
import "./footer.css";
import { NavLink } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import Marquee from "./marquee";


function Footer() {
  // const [click, setClick] = useState(false);

  // const handleClick = () => setClick(!click);


  // const navigate = useNavigate();
  // function handleClickApp(){
  //     navigate("/privacy")
  // }

  return (
    <>
      <div className="footer1">
        <Marquee />
      </div>

      <footer className="footer">
        {/* footer-container */}
        <div className="footerm">
          {/* info */}
          <div className="footerl">
            <div className="footers footf ">
              <div className="tfoot">
                <br />
                <h4 className="pd">Email</h4>
                <div className="mal">
                  <div>
                    <a className="bbbb" href="mailto:contact@poditivity.com">contact@poditivity.com</a>
                  </div>
                </div>
                <h4 className="pd">Resources</h4>
                <div className="fotb">
                  <div className="fotc">
                    <NavLink
                      exact to="/privacy"
                      activeClassName="active"
                    >Privacy Policy
                    </NavLink>
                  </div>
                  <div>
                    <NavLink className="" exact to="/terms" activeClassName="active">Terms & Conditions</NavLink>
                  </div>
                </div>
              </div>
            </div>
            {/* footer-section quick-links wap */}
            <div className="footers footm">
              <div className="bar">
                <div className=" quick-links">
                  <h5 className="lpt lpt2">Recognised By</h5>
                  <div className="imgo">
                    {/* <div> */}
                    <img src={require("../images/images/start-up-1-1024x420-removebg-preview.png")} alt="" className="foot1" />
                    <img src={require("../images/images/SO1.png")} className="foot2" alt="" />
                    <img src={require("../images/images/startup_telangana_logo.jpg")} className="foot6" alt="" />
                    {/* </div> */}
                    <h5 className="lpt lpt2">Our Associations</h5>
                    <div className="imgo2">
                      <img src={require("../images/images/abcd-removebg-preview.png")} className="foot5" alt="" />
                      <img src={require("../images/images/gov-removebg-preview.png")} className="foot4" alt="" />
                      <img src={require("../images/images/1234.png")} className="foot3" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footers fooot">
              <div className="cotation">
                <div>
                  <p className="chap">"For Students</p>
                </div>
                <div>
                  <p className="chap">By Students"</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="poditivity_copyright">
          <h5> Poditivity (Poditivity Advertising & Education Pvt Ltd).<br />
            All Rights Reserved</h5>
        </div>
      </footer>

    </>
  );
}

export default Footer;
