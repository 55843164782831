import React from 'react';
import './Marquee.css';

const Marquee = () => {
  return (
    <div className="marquee-container">
      <div className="marquee"><p>
        Internships & Jobs & Skills & Opportunities & Connections & Community & Safe social media & Workshops & Events & Podcasts Internships & Jobs & Skills & Opportunities & Connections & Community & Safe social media & Workshops & Events & Podcasts Internships & Jobs & Skills & Opportunities & Connections & Community & Safe social media & Workshops & Events & Podcasts
        </p></div>
    </div>
  );
}

export default Marquee;
