import React from 'react';
import "../rools/teem.css";
import office from "../imgbox1/office_Room_1.jpg"
import logo from "../imgbox1/logo.png"


const Teem = () => {

  return (
    <>
      <div className='water'>
        <div className='teemat'>
          <div className='teemat2'>
            <p>poditivity  <br /> Lorem ipsum dolor sit <br />Lorem ipsum dolor sit <br /> amet consectetur</p>
          </div>
          <div className='teematlogo'>
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div>
          <img src={office} className='teem1img' alt="img" />
        </div>
      </div>


      <div className='teem2'>
        <div className='fond1'>
          <div className='image1'>
            <div className='founder'>
              <img src={logo} className='one' alt="1" />
            </div>
            <div className='two'>
              <div>
                <text>hello world</text> <br />
                <text>hello world</text> <br />
                <text>hello world</text>
              </div>
            </div>
          </div>
          <div className='image1'>
            <div className='founder'>
              <img src={logo} className='one' alt="1" />
            </div>
            <div className='two'>
              <div>
                <text>hello world</text> <br />
                <text>hello world</text> <br />
                <text>hello world</text>
              </div>
            </div>
          </div>
        </div>
        <div className='fond2'>
          <div className='image1'>
            <div className='founder'>
              <img src={logo} className='one' alt="1" />
            </div>
            <div className='two'>
              <div>
                <text>hello world</text> <br />
                <text>hello world</text> <br />
                <text>hello world</text>
              </div>
            </div>
          </div >
          <div className='image1'>
            <div className='founder'>
              <img src={logo} className='one' alt="1" />
            </div>
            <div className='two'>
              <div>
                <text>hello world</text> <br />
                <text>hello world</text> <br />
                <text>hello world</text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Teem;