import React from 'react';
import './Features.css';
import podcast from '../images/images/Features/podcastC.png';
import library from '../images/images/Features/libraryC.png';
import analytics from '../images/images/Features/analyticsC.png';
import connections from '../images/images/Features/connectionsC.png';
import platform from '../images/images/Features/social-platformC.png';
import events from '../images/images/Features/eventsC.png';

const featuresData = [
  {
    id: 1,
    image: podcast,
    heading: 'Podcasts',
    content: 'Unleash your voice! Poditivity lets students create and share podcasts, fostering a vibrant space for expressive learning.'
  },
  {
    id: 2,
    image: library,
    heading: 'Library Feature',
    content: 'Collaborative learning simplified! Poditivity\'s library ensures seamless sharing and retrieval of notes for effective academic support.'
  },
  {
    id: 3,
    image: analytics,
    heading: 'Uni Analytics',
    content: 'Empower your institution! Poditivity offers analytics for universities, enhancing insights into student engagement and academic trends.'
  },
  {
    id: 4,
    image: connections,
    heading: 'Connections',
    content: 'Bridging the gap! Poditivity facilitates direct connections between students, universities, and corporates for seamless collaboration and growth.'
  },
  {
    id: 5,
    image: platform,
    heading: 'Secure Social Platform',
    content: 'Connect with confidence! Poditivity prioritizes safety, providing a secure social media space for students to engage, share, and learn.'
  },
  {
    id: 6,
    image: events,
    heading: 'Events & Workshops',
    content: 'Elevate your experience! Poditivity hosts exciting events and workshops, adding value to your academic journey through diverse learning opportunities.'
  }
];

const Feature = ({ image, heading, content }) => (
  <div className="feature">
    <div className="imageg">
      <img src={image} alt={heading} />
    </div>
    <div className="heading">
      {heading}
    </div>
    <div className="content">
      {content}
    </div>
  </div>
);

const Features = () => (
  <section className="features" id="features">
    <div className="features-container">
      {featuresData.map(feature => (
        <Feature key={feature.id} {...feature} />
      ))}
    </div>
  </section>
);

export default Features;
