import React from 'react';
import nut from '../imgbox1/IMG_6423.5ce644e496fc84740f78-removebg-preview.png';

function InstitutionForm() {
  return (
    <div>
      <div>
        <img src={nut} className='iimg' alt="img" />
      </div>
      <div>
        <h1 className='isoon'>Institutions registration will be open soon!</h1>
      </div>
      <div className='pt-3'>
        <h1 className='ilive'>Register with us now and you will be the first one to be notified when wengo live.</h1>
      </div>
      <div className='ib'>
        <a className=' hover:bg-black inow' href="/">Register Now</a>
      </div>
    </div>
  );
}

export default InstitutionForm;


