import "./Student.css";
import "../Institution/Courses.css";
import laptop from '../imgbox1/ONEST NETWORK desktopimg.png'
import phonea from '../imgbox1/Onest mobile img1.png'
import { Link } from "react-router-dom";


const Student = () => {




  
  return (
    <>
      <div className='student_header'>
        <h2 className='student_header_text'> Turn Your Vision Into Reality</h2>
        <h5 className='student_header_text2'>Welcome to <span className='podito'>PODITIVITY</span>, where students thrive and dreams take flight</h5>
      </div>


      <section className='studentfor'>
        <div className='doll'>
          <div className='bbox1 '>
            <div className='smbox'>
              <p className='AFS h2'>OUR <br />OFFERINGS</p>
            </div>
          </div>
          <div className='bbox2'>
            <div>
              <div className='box bix2'>
                <img src={require("../imgbox1/student/safe_social_media.png")} alt="safe-social-media" className='imgs' border="0" />
                <p className='head'>Safe social media</p>
                <p className='leg'>Safe and secure environment for early graduates</p>
              </div>
              <div className='box bix bix1'>
                <img src={require("../imgbox1/student/strategic_guidance.png")} className='imgs' alt="img" />
                <p className='head'>Strategic Guidance</p>
                <p className='leg'>Get guidance with world class leaders from your specific industry    </p>
              </div>
            </div>

            <div className='block2'>
              <div className='box bix1'>
                <img src={require("../imgbox1/student/communication sp .png")} className='imgs' alt="img" />
                <p className='head'>Easy communication</p>
                <p className='leg'>Seamless communication with Institutions faculty</p>
              </div>
              <div className='box bix bix2'>
                <img src={require("../imgbox1/student/community.png")} className='imgs' alt="img" />
                <p className='head'>Community Support</p>
                <p className='leg'>Multi-college community with a network of colleges, industry leaders and like minded peers     </p>
              </div>
            </div>
          </div>
        </div>
      </section>




      <div>
        <h2 className='onestv h2'>
        Powered by <Link to='https://onest.network/' className='linkword' > ONEST</Link>
        </h2>
      </div>

      <div className="onest">
        <div className="video-container laptopa">
          <img src={laptop} alt="img" />
        </div>
      </div>


      <div className="onest1">
        <div className="video-container phonea">
        <img src={phonea} alt="img" />
        </div>
      </div>

    </>
  );
}

export default Student;
