import React from 'react';

function Buttonl({ label, onClick }) {
  return (
    <buttonl className="custom-buttonl" onClick={onClick}>
      {label}
    </buttonl>
  );
}

export default Buttonl;


