import React from 'react';
import "../rools/terms.css";
// import "../rools/privacy.css";
import logo from "../imgbox1/logo.png";

const Terms = () => {

  return (
    <>
      <div class="kris-kross">
        <div class="linet linet1"></div>
        <div class="linet linet2"></div>
      </div>
      <div className='terms'>

        <div className='terms1'>
          <div className='termsa'>
            <div className='privp'>
              <div>
                <img src={logo} className='privacylogo' alt="img" />
              </div>
              <div className='privacylogo1' >
                PODITIVITY
              </div>
            </div>
          </div>

          <div className='word boldtext'>
            <p>TERMS & CONDITIONS</p>
          </div>
          <h4>Effective Date: 01-08-2024</h4>
          <div className='termsb'>
            <div>
              <text className='boldtext'>Acceptance of Terms </text> <br />
              <li>By accessing and using the Poditivity website, you agree to comply with and be bound by these Terms & Conditions. If you do not agree with these terms, please do not use our website.              </li>
            </div>

            <br />
            <div>
              <text className='boldtext'>Use of the Website </text> <br /> 
              <li><span className='boldtext'>Eligibility:</span> You must be at least 13 years old to use this website. By using the site, you represent that you meet this age requirement</li><br />
              <li><span className='boldtext'>Permitted Use:</span> You may use our website for lawful purposes only. You agree not to use the website for any illegal or unauthorized purposes, including but not limited to violating any intellectual property or privacy rights.              </li><br />
              <li><span className='boldtext'>Content Ownership:</span> All content on this website, including li, graphics, logos, and images, is the property of Poditivity and is protected by intellectual property laws. You may not reproduce, distribute, or use any content without prior written permission from Poditivity.              </li>
            </div>
            <br />
            <div>
              <text className='boldtext'> Contact Us Submissions </text> <br />
              <li><span className='boldtext'>User Responsibility:</span> When submitting inquiries or information through the "Contact Us" page, you agree to provide accurate and truthful information.</li><br />
              <li><span className='boldtext'>Use of Submissions:</span> By submitting information through the "Contact Us" page, you grant Poditivity the right to use the information provided to respond to your inquiry or for other purposes related to your request.              </li><br />
            </div>
            <br />
            <div>
              <text className='boldtext'>Disclaimer of Warranties              </text> <br />
              <li><span className='boldtext'>Website Availability:</span> Poditivity strives to ensure the website is available at all times, but we do not guarantee that the website will be uninterrupted or error-free. We reserve the right to suspend or discontinue the website at any time without notice.              </li> <br />
              <li><span className='boldtext'>Information Accuracy:</span> The information provided on this website is for general informational purposes only. While we strive for accuracy, we do not warrant the completeness or reliability of any content on the website.              </li>
            </div>
            <br />
            <div>
              <text className='boldtext'>Limitation of Liability </text> <br />
              <li>Poditivity shall not be liable for any damages arising from the use or inability to use the website, including but not limited to direct, indirect, incidental, punitive, and consequential damages. Your use of the website is at your own risk.              </li>
            </div>
            <br />
            <div>
              <text className='boldtext'>  Links to Third-Party Sites              </text> <br />
              <li>Our website may contain links to third-party websites. These links are provided for your convenience only. Poditivity does not endorse or take responsibility for the content, accuracy, or practices of these third-party sites. Accessing linked sites is at your own risk.              </li>
            </div>
            <br />
            <div>
              <text className='boldtext'> Privacy </text> <br />
              <li>Your use of the website is also governed by our Privacy Policy, which outlines how we collect, use, and protect your information.              </li>
            </div>
            <br />
            <div>
              <text className='boldtext'> Changes to Terms & Conditions              </text> <br />
              <li>Poditivity reserves the right to modify these Terms & Conditions at any time. Any changes will be posted on this page with an updated effective date. Your continued use of the website after changes are posted constitutes your acceptance of the revised terms.              </li>
            </div>
            <br />
            <div>
              <text className='boldtext'> Governing Law              </text> <br />
              <li>These Terms & Conditions are governed by and construed in accordance with the laws of India Telangana, without regard to its conflict of law principles.              </li>
            </div>
            <br />
            <div>
              <text className='boldtext'> Contact us              </text> <br />
              If you have any questions or concerns about these Terms & Conditions, please contact us at:           
            </div>

            <div>
              <text className='boldtext'>Poditivity:</text> Kazipet, Hanumakonda, Warangal-506003, Telangana <br />
              <text className='boldtext'>Email:</text> <a href="mailto:contact@poditivity.com">contact@poditivity.com</a><br />
            </div> 
          </div>
        </div>
      </div>

    </>
  );
}

export default Terms;