import React from 'react';

const CirclesAndLine = () => {
  return (
    <>
      <div className='screenvx'>
        <div className='laptop'>
          <div className='laptop1'>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/register.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 1</div>
                  <div className='cntu chart'>Register</div>
                </div>
              </div>
            </div>
            <div className='linex'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/customize.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 2</div>
                  <div className='cntu chart'>Customize</div>
                </div>
              </div>
            </div>
            <div className='linex'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/connect.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 3</div>
                  <div className='cntu chart'>Connect</div>
                </div>
              </div>
            </div>
          </div>
          <div className='laptop2'>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/integrate.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 4</div>
                  <div className='cntu chart'>Integrate</div>
                </div>
              </div>
            </div>
            <div className='linex'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/launch.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 5</div>
                  <div className='cntu chart'>Launch</div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className='padv'>
          <div className='padv1'>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/register.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 1</div>
                  <div className='cntu chart'>Register</div>
                </div>
              </div>
            </div>
            <div className='linexy'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/customize.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 2</div>
                  <div className='cntu chart'>Customize</div>
                </div>
              </div>
            </div>
          </div>
          <div className='padv1'>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/connect.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 3</div>
                  <div className='cntu chart'>Connect</div>
                </div>
              </div>
            </div>
            <div className='linexy'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/integrate.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 4</div>
                  <div className='cntu chart'>Integrate</div>
                </div>
              </div>
            </div>
          </div>
          <div className='padv1'>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/launch.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 5</div>
                  <div className='cntu chart'>Launch</div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className='mobilev'>
          <div className='mobilev1'>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/register.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 1</div>
                  <div className='cntu chart'>Register</div>
                </div>
              </div>
            </div>
            <div className='linev'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/customize.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 2</div>
                  <div className='cntu chart'>Customize</div>
                </div>
              </div>
            </div>
            <div className='linev'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/connect.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 3</div>
                  <div className='cntu chart'>Connect</div>
                </div>
              </div>
            </div>
            <div className='linev'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/integrate.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 4</div>
                  <div className='cntu chart'>Integrate</div>
                </div>
              </div>
            </div>
            <div className='linev'></div>
            <div className='bing'>
              <div className='bing1'>
                <div className='boxx cntu'>
                  <img src={require("../images/images/institution/launch.png")} className='boy' alt="ll" />
                </div>
                <div className='cnt'>
                  <div className='cntu' >STEP 5</div>
                  <div className='cntu chart'>Launch</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

 
    </>
  );
};

export default CirclesAndLine;
