import React, { useEffect, useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
// import { MdOutlineEmail, MdMenu, MdClose } from "react-icons/md";

function NavBar() {
  const [click, setClick] = useState(false);
  const menuRef = useRef(null);

  const handleClick = () => setClick(!click);

  const closeMenu = () => setClick(false);

  // const navigate = useNavigate();
  // function handleClick() {
  //   navigate("/")
  // } onClick={handleClick}

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (click) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [click]);

  return (
    <>
      <nav className="navbar">
        <section className="nav-container" ref={menuRef}>
          <div >
            <Link to="/"><img src={require("../images/images/logoB.png")} className="logoimg" alt="img" /></Link>
          </div>
          {/* <div className="logop">
            <img
              src={require("../images/images/logoB.png")}
              src={require("../images/images/logo.png")}
              className="logo"
              alt="img"
            />
            <div className="podi">PODITIVITY</div>
          </div> */}

          <div className="supers">
            <div role="navigation">
              <div id="menuToggle">
                <input type="checkbox" checked={click} onChange={handleClick} />
                <span></span>
                <span></span>
                <span></span>

                <ul id="menu">
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/"
                      activeClassName="active"
                      className="nav-links"
                      onClick={closeMenu}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/about"
                      activeClassName="active"
                      className="nav-links"
                      onClick={closeMenu}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/student"
                      activeClassName="active"
                      className="nav-links"
                      onClick={closeMenu}
                    >
                      For Students
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/institute"
                      activeClassName="active"
                      className="nav-links"
                      onClick={closeMenu}
                    >
                      For Institutions
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/poditivitycenter"
                      activeClassName="active"
                      className="nav-links"
                      onClick={closeMenu}
                    >
                      Poditivity Center
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/contact"
                      activeClassName="active"
                      className="nav-links"
                      onClick={closeMenu}
                    >
                      Contact us
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <div className="login">
                      <NavLink
                        exact
                        to="/signup"
                        activeClassName="active"
                        className="nav-links"
                        onClick={closeMenu}
                      >
                        Sign In/ Login
                      </NavLink>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={closeMenu}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={closeMenu}
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/student"
                activeClassName="active"
                className="nav-links"
                onClick={closeMenu}
              >
                For Students
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/institute"
                activeClassName="active"
                className="nav-links"
                onClick={closeMenu}
              >
                For Institutions
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/poditivitycenter"
                activeClassName="active"
                className="nav-links"
                onClick={closeMenu}
              >
                Poditivity Center
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={closeMenu}
              >
                Contact us
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <div className="login">
                <NavLink
                  exact
                  to="/signup"
                  activeClassName="active"
                  className="nav-links"
                  onClick={closeMenu}
                >
                  Sign In/ Login
                </NavLink>
              </div>
            </li> */}
          </ul>
        </section>
      </nav>
    </>
  );
}

export default NavBar;
