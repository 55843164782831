import React from 'react';
import "../rools/privacy.css";
import logo from "../imgbox1/logo.png";


const Privacy = () => {

  return (
    <>
      <div class="kris-kross">
        <div class="linep linep1"></div>
        <div class="linep linep2"></div>
      </div>
      <div className='privacy'>

        <div className='privacy1'>
          <div className='privacya'>
            <div className='privp'>
              <div>
                <img src={logo} className='privacylogo' alt="img" />
              </div>
              <div className='privacylogo1' >
                PODITIVITY
              </div>
            </div>
          </div>

          <div className='word '>
            <p> PRIVACY POLICY </p>
          </div>
          <h4>Effective Date: 01-08-2024</h4>
          <div className='privacyb'>
            <p>
              <text className='boldtext'>Introduction</text> <br />
               Welcome to the Poditivity! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website or use our "Contact Us" page.
            </p>
            <div >
              <text className='boldtext'>Information We Collect</text><br />
              <text> We collect minimal personal information through our website, primarily when you choose to contact us: </text><br />
              <li> <span className='boldtext'> Contact Information:</span> If you submit a query through our "Contact Us" page, we may collect your name, email address, phone number, and any other information you voluntarily provide in your.</li> 
              <li> <span className='boldtext'>Usage Data:</span> We may collect non-personal information about your visit, such as your IP address, browser type, and the pages you viewed, to help us improve our website.</li>
            </div>
            <br />
            <div>
              <text className='boldtext'>How We Use Your Information</text><br />
              <text>  We use the information you provide for the following purposes:</text><br />
              <li><span className='boldtext'> Responding to Inquiries:</span> We use the contact information you provide to respond to your queries or requests for information about Poditivity. </li>
              <li><span className='boldtext'>Improving Our Website:</span>  Usage data helps us analyze how visitors interact with our website so that we can enhance the user experience. </li><br />
            </div>
            <br />
            <div >
              <text className='boldtext'> Sharing Your Information </text><br />
              <text> We do not sell or share your personal information with third parties, except in the following circumstances:              </text><br />
              <li> <span className='boldtext'>Legal Requirements:</span> We may disclose your information if required by law or to protect the rights and safety of Poditivity, our users, or others. </li><br />
            </div>
            <br />
            <div >
              <text className='boldtext'> Your Rights</text> <br />
              <text> You have the right to:  </text><br />
              <li><span className='boldtext'>Access and Correct:</span>  Request access to the personal information we have about you and request corrections if necessary. </li>
              <li><span className='boldtext'>Deletion:</span> Request the deletion of your personal information from our records, subject to legal obligations.</li><br />
            </div>
            <br />
            <div>
              <text className='boldtext'>Data Security</text><br />
              <text>We take reasonable precautions to protect your information. However, please note that no method of transmission over the internet is completely secure, and we cannot guarantee the absolute security of your data.</text>
            </div>
            <br />
            <div>
              <text className='boldtext'>Cookies & Tracking Technologies </text><br />
              <text>Our website may use cookies or similar tracking technologies to improve user experience. You can manage your cookie preferences through your browser settings.            </text>
            </div>
            <br />
            <div>
              <text className='boldtext'>Third-Party Links</text><br />
              <text>Our website may contain links to third-party sites. We are not responsible for the privacy practices of these sites, and we encourage you to review their privacy policies.            </text>
            </div>
            <br />
            {/* <div>
              <text></text>
              <text>Introduction Welcome to the Poditivity! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website or use our "Contact Us" page.</text>
            </div>
            <br /> */}
            <div>
              <text className='boldtext'>Contact Us</text><br />
              <text> If you have any questions or concerns about this Privacy Policy, please contact us at:              </text><br />
               <span className='boldtext'>Poditivity:</span> Kazipet, Hanumakonda, Warangal-506003, Telangana <br />
               <span className='boldtext'>Email:</span> <a href="mailto:contact@poditivity.com">contact@poditivity.com</a><br />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;