import React, { useState } from 'react';
import './Contact.css';

const ContactUs = () => {
  const [isInstitutionSelected, setIsInstitutionSelected] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleRadioChange = (event) => {
    setIsInstitutionSelected(event.target.value === 'institution');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
        const myForm = document.getElementById('contact');
        const formData = new FormData(myForm);
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => setIsSubmitted(true))
            .catch((error) => alert(error));
  };

  const handleOkClick = () => {
    setIsSubmitted(false);
  };

  return (
    <div className="container">
      <div className="innerwrap">
        <div className='header_contact'>
          <p className='header_text_contact h2'>Contact us</p>
          <p className='headertext_contact contactpg'>At Poditivity, we're dedicated to providing exceptional support and assistance to our users. Whether you have questions, feedback, or inquiries, our team is here to help.</p>
        </div>
        <br /><br /><br /><br />
        <div className='about_us_header'>
          <div className='location'>
            <img src={require("../images/images/location.png")} alt='location' className='local' />
            <p className='location_text'> Kazipet,  Hanumakonda, <br /> Warangal-506003, Telangana</p>
          </div>
          <div className='mobile'>
            <img src={require("../images/images/email12.png")} alt='email' className=' local local1' />
            <p className='mobile_text'>Email us : <a href="mailto:contact@poditivity.com">contact@poditivity.com</a>  </p>
          </div>
        </div>
        <br /><br /><br /><br />

        <section className="section2 clearfix">
          <div className="col2 column1 first">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3795.1434677963516!2d79.502943!3d17.972061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDU4JzE5LjQiTiA3OcKwMzAnMTAuNiJF!5e0!3m2!1sen!2sin!4v1718048381516!5m2!1sen!2sin"
              width="100%"
              height="650"
              style={{ border: 0 }}
              title="Location map of our office"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col2 column2 last">
            <div className="sec2contactform">
              <p className="sec2frmtitle">Have questions or inquiries? We're here to help!</p>
              {!isSubmitted ? (
                <form
                  onSubmit={handleSubmit}
                  id='contact'
                  name="contact"
                  method="post"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="form-group sign-up-from" style={{ marginBottom: '3rem' }}>
                    <div className='hfrom'>
                      <label style={{ padding: "0px" }}>
                        <input
                          type="radio"
                          name="userType"
                          value="student"
                          checked={!isInstitutionSelected}
                          onChange={handleRadioChange}
                        />
                        Student
                      </label>
                      <label style={{ padding: "0rem", border: '1px' }}>
                        <input
                          type="radio"
                          name="userType"
                          value="institution"
                          checked={isInstitutionSelected}
                          onChange={handleRadioChange}
                        />
                        Institution
                      </label>
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="input-pair emcn">
                      <div className="input-group ">
                        <label className="text_col">Name</label>
                        <input className="col2 first"
                          width="auto"
                          type="text"
                          placeholder="Enter your Name"
                          name="name"
                          required
                        />
                      </div>
                      <div className="input-group">
                        <label className="text_col">College name</label>
                        <input className="col2 last"
                          width="auto"
                          type="text"
                          placeholder="Enter your college name"
                          name="college"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="input-pair emcn">

                      <div className="input-group ">
                        <label className="text_col">Email</label>
                        <input className="col2 first"
                          type="email"
                          placeholder="abc@gmail.com"
                          name="email"
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label className="text_col">Number</label>
                        <input className="col2 last"
                          type="text"
                          inputMode='numeric'
                          maxLength={10}
                          placeholder="0000000000"
                          name="number"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-group '>
                    <div className="input-group">
                      <label className="text_col">College Location</label>
                      <input className="col2 last"
                        type="text"
                        placeholder="College city"
                        name="location"
                        required
                      />
                    </div>
                  </div>
                  {isInstitutionSelected && (
                    <div className="form-group">
                      <div className="input-group">
                        <label className="text_col">Designation</label>
                        <input className="col2 first "
                          type="text"
                          placeholder="Your post in college"
                          name="designation"
                          required
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <div className="input-group">
                      <label className="text_col">Your Message</label>
                      <textarea name="message"
                        cols="30" rows="7"
                        placeholder="Start typing ......"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className='Submit' value="Submit">Submit</button>
                  </div>
                </form>
              ) : (
                <div className="thank-you-container">
                  <p className='tanku'>Thank you for submitting the form! <i className="fa fa-check-circle" aria-hidden="true"></i></p>
                  <button onClick={handleOkClick}>OK</button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactUs;
