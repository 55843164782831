// import React, { useState } from "react";
import './poditivity.css';
import { useNavigate } from "react-router-dom";
import nut from '../imgbox1/unnamed.png';

function PoditivityCenter() {

    const navigate = useNavigate();

function handleClick(){
    navigate("/")
}

    return(<>
    <div className="Poditivity_Bg">
        <span className="ComingSoon">COMING SOON</span><br/>
        <img src={nut} className="nutp" alt="img" /> <br/>
        <p>This page is under construction. Stay tuned!!</p>
        <button className="HomeR" onClick={handleClick} > Home</button>
    </div>
    </>);
}
export default PoditivityCenter;