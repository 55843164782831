import React, { useEffect, useState } from "react";
import "./Institution.css";
import { Link } from "react-router-dom";
import CirclesAndLine from "../../CirclesAndLine/CirclesAndLine";
import digital from '../imgbox1/institution/digital.png';
import efficiency from '../imgbox1/institution/efficiency.png';
import engagement from '../imgbox1/institution/engagement.png';
import customize from '../imgbox1/institution/customize.png';
import communication from '../imgbox1/institution/communication.png';
import phone1 from '../imgbox1/iphone1.png';
import phone2 from '../imgbox1/iphone2.png';
import phone3 from '../imgbox1/iphone3.png';



function Institution() {
  const advantagesData = [
    { text: "Complete Digital Onboarding", imgSrc: digital },
    { text: "Efficient Communication Management", imgSrc: efficiency },
    { text: "Gamified Engagement", imgSrc: engagement },
    { text: "Customised Faculty Accounts", imgSrc: customize },
    { text: "Multi-College Ecosystem ", imgSrc: communication }
  ];







  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    { src: phone1, height: '200px', width: '100px' },
    { src: phone2, height: '200px', width: '100px' }, 
    { src: phone3, height: '200px', width: '100px' }, 
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // Rotate every 6 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const getImageOrder = (index) => {
    const order = [];
    for (let i = 0; i < images.length; i++) {
      order.push(images[(index + i) % images.length]);
    }
    return order;
  };

  const orderedImages = getImageOrder(currentIndex);




  // Rotate every 6 seconds





  return (
    <>
      <div className="Institute_header">
        <span className="Edu">Education Made Seamless</span>
        <span className="welcome">Welcome to <span className='podito'>PODITIVITY</span>, your gateway to a world of educational excellence.</span>
      </div>

      <div className="Advantages">
        <text className=" h2">UNIQUE ADVANTAGES FOR INSTITUTIONS</text>
        <span className="signupInstitute">
          {/* <Button className="signup">Sign up</Button> */}
        </span>
      </div>

      <div className="Advantages2">
        {advantagesData.map((advantage, index) => (
          <div key={index} className="DigitalProcess">
            <img src={advantage.imgSrc} alt="advantage" className="advantage-img" />
            <span className="advantage-txt">{advantage.text}</span>
          </div>
        ))}
      </div>


      <div className="steps_images">
        <div className="steps">
          <span className="steps_text h2">5 Simple steps to get onboarded</span>
        </div>
        <CirclesAndLine />
      </div>







      <div className="myapp">
        <div className="appgal">
          <div className="appgal1">
            <div className="image-container">
              {orderedImages.map((image, index) => (
                <img
                  key={index}
                  src={image.src}
                  alt="app"
                  style={{ height: image.height, width: image.width }}
                  className={`image-itemk ${index === 1 ? 'middle' : ''}`} // Apply 'middle' class to the middle image
                />
              ))}
            </div>
          </div>

        </div>
        <div className="Footer_Institute">
          <div className="instibox">
            <span className="News">Exciting News!</span><br />
            <p className="app_institute">Our App is Coming Soon</p>
            <div className="appwall">

              <div className="playb">
                <Link to="/poditivitycenter"><img src={require("../imgbox1/google-play-badge.png")} alt="GooglePlay" style={{ width: '150px', height: '50px', margin: '0px' }} /></Link>
              </div>
              <div className="playb">
                <Link to="/poditivitycenter"><img src={require("../imgbox1/app-store-badge.png")} alt="AppStore" className="appstorel" /></Link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Institution;
