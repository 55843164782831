
import React from 'react';
import "./about.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div>
        <div className='center-heading'>
          <h2 className='h2'>For Students !! <br /> By Students !!</h2>
        </div>
        <div className='offerings'>
          <div className='forstudents'>
            <div className='omg'>
              <div>
                <p className='our'>Our</p>
                <p className='mission'>Mission</p>
              </div>
              <div>
                <img src={require("../imgbox1/mission.png")} alt="mission" border="0" className='' />
              </div>
            </div>
            <hr></hr>
            <p className='pg'>Empower every student to achieve their fullest potential through seamless access to upskilling, mentorship, and holistic support.</p>
          </div>
          <div className='forinstitutions'>
            <div className='ov'>
              <div>
                <p className='our'>Our</p>
                <p className='mission'>Vision</p>
              </div>
              <div>
                <img src={require("../imgbox1/vision.png")} alt="vision" border="0" className='' />
              </div>
            </div>
            <hr></hr>
            <p className='pg'>To bridge the communication gap between students and institutions, fostering an inclusive community that equips students with essential skills, resources, and opportunities for success.</p>
          </div>
        </div>
        <div className='poditivity'>
          <img src={require("../imgbox1/quotation_left.png")} className='colam colam1' alt="" />
          <div className='heading'>
            <h2 className='h2'>We are Poditivity</h2>
          </div>
          <div className='pgl'>
            <div className='part1'>
              <p className='text1'> In a world where education often feels like an exclusive privilege, Poditivity is redefining the landscape. We believe that every student, regardless of their background, should
                have access to quality upskilling, mentorship, and holistic support.</p>
            </div>
            <p className='text2'>Our goal? To revolutionise the educational experience by bridging the communication gap between students and institutions. Picture this: A nation wide multi college community for early graduates where they get seamless access to essential skills, resources, and opportunities that empower them to achieve their fullest potential. Our platform connects students with industry experts, provides
              tailored workshops, and fosters a multi-college community that supports personal and professional growth.            </p>
            <p className='text4 text2'>Our Vision and Mission directly aligns with  <Link className='nep' to="https://www.education.gov.in/sites/upload_files/mhrd/files/NEP_Final_English.pdf">National Educational Policy</Link>  and our tailor made application acts as a catalyst in achieving holistic development for higher education system.            </p>
            <div className='part1'>
              <p className='text3'>We're not just for students; we also empower educational institutions. By partnering with Poditivity, institutions can enhance  day-to-day communication, streamline learning management, and provide better resources and support to their students. Join us in creating an inclusive, empowering educational environment for everyone.            </p>
              <img src={require("../imgbox1/quotation_right.png")} className='colam colam2' alt="" />
            </div>
          </div>
        </div>
        <section className='recognitions'>
          <p className='heading2'><h2 className='h2'>Recognitions</h2></p>
          <div className='certificates'>
            <img src={require("../imgbox1/LIMG1.png")} alt="india" className='GIMG3' border="0" />
          </div>
          <div className='certificate'>
            <img src={require("../imgbox1/LIMG3.png")} alt="telanga" className='GIMG1' border="0" />
            <img src={require("../imgbox1/LIMG2.png")} alt="odisa" className='GIMG2' border="0" />
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
