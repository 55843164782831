import React, { useState } from 'react';
import './jap.css';
import Buttonl from './Buttonl';
import InstitutionForm from './InstitutionForm';
import StudentForm from './StudentForm';

function Signup () {
  const [activeForm, setActiveForm] = useState('student'); // Set the initial state to 'student'

  const handleButtonlClick = (formType) => {
    setActiveForm(formType);
  };

  return (
    <>
      <div className="jap form-container">
        <p className="top">Login to your account as</p>
        <div className="buttonl-container">
          <div 
            className={`mr-2 becy ${activeForm === 'institution' ? 'active' : ''}`} 
            onClick={() => handleButtonlClick('institution')}
          >
            <i className="fa-solid fa-rocket"></i>
            <Buttonl label="Institution" />
          </div>
          <div 
            className={`mily ${activeForm === 'student' ? 'active' : ''}`} 
            onClick={() => handleButtonlClick('student')}
          >
            <i className="fa-solid fa-user-tie"></i>
            <Buttonl label="Student" />
          </div>
        </div>
        <div className="">
          {activeForm === 'institution' && <InstitutionForm />}
          {activeForm === 'student' && <StudentForm />}
        </div>
      </div>
    </>
  );
}

export default Signup;








