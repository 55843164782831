// src/pages/Blog.js
import React from 'react';

const Blog = () => {
  return (
    <div>
      <h1>Blog Page</h1>
      <p>Our latest blog posts go here.</p>
    </div>
  );
}

export default Blog;
