import React from 'react';

function StudentForm() {
  return (
    <div className='studentfz'>
      <div className='text-sm'>
       {/* <label for="email" class="mx-2 sm:mx-7 sm:text-xl">Email ID</label> */}
       <h1 class="sid">Email ID</h1>
      </div>
      <div class="">
        <input class=" sbx" type="email" name="" id="email"placeholder="  Type here" required />
      </div>
      <div class=" sins">
        <a class="sm:mx-7 sin" href="/">Login</a>
      </div>
      <div>
        <p className='title'> or continue with</p>
      </div>
      <div class="sm:mx-7 sgol ">
        <a class=" sgle " href="/"> <i class="fa-brands fa-google"></i> Continuous with google</a>
      </div>
      <div className=' slin '>
        <div class=" text-center ">
          <text>Don't have an account?</text>
        </div>
        <div class=" text-center  sre">
         <text><a href="# " class="underline">Signup here</a></text>
        </div>  
      </div>   
    </div>  
  );
}

export default StudentForm;
